.navbar_top {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px 0;

  .navbar {
    padding: 0;
  }

  .contact_link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 12px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #354141;

        i {
          margin-right: 10px;
        }
      }
    }
  }

  ul.navTopRight {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 12px;
      padding: 0 10px;
      border-right: 1px solid #cad5d5;

      &:last-child {
        padding-right: 0;
        border-right: none;
      }

      a {
        color: #354141;
        text-decoration: none;
        i {
          margin-right: 10px;
        }
      }

      select {
        border: none;
      }

    }
  }
}