
.sdfsdfsd {
    padding: 30px 0 20px;
}




.footer_top {
    
    .footer_contact {
        .footer_logo {
            margin-bottom: 15px;
            a {
                display: inline-block;

                img {
                    max-width: 130px;
                    width    : 100%;
                }
            }
        }

        .footer_call_us {
            margin-bottom: 15px;
            .media {
                .call_us_icon {
                    padding-right: 20px;

                    i {
                        font-size: 65px;
                        color    : $brand_color;
                    }
                }

                .media_body {
                    .call_us_text {
                        font-size  : 13px;
                        line-height: 18px;
                        display    : block;
                    }

                    .call_us_number {
                        font-size  : 22px;
                        line-height: 30px;
                        display    : block;
                    }
                }
            }
        }

        .footer_address {
            .footer_address_title {
                font-size    : 14px;
                font-weight  : 700;
                line-height  : 23px;
                margin-bottom: 0;
            }

            .footer_address_para {
                font-size  : 14px;
                font-weight: 400;
                line-height: 23px;
            }
        }

        .footer_social {
            .social_link {
                margin    : 0;
                padding   : 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        display: block;
                        color: $brand_color;
                        text-decoration: none;
                        font-size: 22px;

                        i {}
                    }
                }
            }
        }
    }

    .footer_single_item {
        .footer_title {
            font-size    : 16px;
            font-weight  : 700;
            line-height  : 18px;
            margin-bottom: 15px;
        }

        .footer_menu {
            margin    : 0;
            padding   : 0;
            list-style: none;

            li {
                a {
                    display        : inline-block;
                    font-size      : 14px;
                    font-weight    : 400;
                    line-height    : 24px;
                    margin-bottom  : 5px;
                    color          : $dark_color;
                    position       : relative;
                    text-decoration: none;

                    &::before {
                        position  : absolute;
                        content   : ""; 
                        bottom    : 0;
                        height    : 1px;
                        background: $dark_color;
                        right     : 0;
                        width     : 0;
                        transition: all 0.5s;
                    }

                    &:hover {
                        &:before {
                            right: auto;
                            left : 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}