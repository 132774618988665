// fonts
$pop : 'Poppins', sans-serif;

//colos

$brand_color: #1e3c72;
$brand_light: #274c8f;
$brand_red: #b4111a;
$brand_red_light: #ed3640;
$brand_dark: #072a6a;
$brand_hover: #072a6a;
$w_color: #ffffff;
$dark_color: #333e48;
$borderColor : #dee2e6;
$gray : gray;


// Font size

$f-8 : 8px;
$f-12 : 12px;
$f-14 : 14px;
$f-15 : 15px;
$f-20 : 20px;