@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');

html {
  scroll-behavior: smooth;
}

body {
  font-family: $pop;
}

a:hover {
  text-decoration: none;
}

.container {
  max-width: 1200px;
}

.breadcrumb {
  background: transparent;

  li {
    position: relative;

    &:last-child {
      a {
        pointer-events: none;
      }
    }

    & + li {

      padding-left: 25px;
      margin-left: 25px;

      &::before {
        position: absolute;
        content: ">";
        left: 0;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: $dark_color;
    }

  }
}