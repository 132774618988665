.productListWrap {
  padding: 30px 0;
}

.produt_top {
  margin-bottom: 20px;
  border-bottom: 1px solid $borderColor;
}

.sec-title {
  font-size     : 22px;
  font-weight   : 400;
  line-height   : 28px;
  display       : inline-block;
  position      : relative;
  margin        : 0;
  padding-bottom: 5px;

  &::before {
    position  : absolute;
    content   : "";
    bottom    : 0;
    left      : 0;
    height    : 2px;
    width     : 100%;
    background: $brand_color;
  }
}

.productSingleItem {
  position: relative;

  &:first-child {
    &::before {
      display: none;
    }
  }

  &::before {
    position  : absolute;
    content   : "";
    left      : 0px;
    width     : 1px;
    height    : 70%;
    top       : 50%;
    transform : translateY(-50%);
    background: $borderColor;
  }

  .product {
    padding: 20px;

    & p {
      &.product-category {
        a {
          display        : inline-block;
          color          : $gray;
          font-size      : $f-12;
          text-decoration: none;

          &:hover {
            color: $dark_color;
          }
        }
      }
    }

    & p {
      &.product-title {
        a {
          display    : inline-block;
          color      : $brand_color;
          font-size  : $f-14;
          font-weight: 600;
        }
      }
    }

    .product-image {
      display        : flex;
      align-items    : center;
      justify-content: center;

      a {
        display: inline-block;

        img {
          display: inline-block;
        }
      }
    }

    & p {

      margin: 0;

      &.product-price {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        padding-bottom : 10px;


        .price {
          font-size: 20px;
        }

        .product-cart {
          a {
            display        : flex;
            align-items    : center;
            justify-content: center;
            width          : 34px;
            height         : 34px;
            border-radius  : 50%;
            background     : #eae8e8;
            color          : #fff;
          }
        }
      }

    }

    .product-footer {
      border-top     : 1px solid $borderColor;
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      padding-top    : 5px;
      opacity        : 0;

      a {
        display  : inline-block;
        font-size: 13px;
        color    : $gray;

        i {
          margin-right: 5px;
        }

        &:hover {
          color: $dark_color;
        }
      }
    }
  }



  &:hover {
    &::before {
      display: none;
    }

    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

    .product {
      p.product-price .product-cart a {
        background: $brand_color;
      }

      .product-footer {
        opacity: 1;
      }
    }
  }
}


.ListView {
  .productSingleItem {
    &::before {
      width    : 100%;
      height   : 1px;
      top      : 0;
      transform: translateY(0);
    }

    .reviewStar {
      position: absolute;
      bottom  : 10px;
    }

    .product {
      .product-footer {
        opacity: 1;
      }
    }
  }
}

.sideeBarFilterWrap {
  .produt_top {}
}

.filterSingleItem {
  &+.filterSingleItem {
    padding-top: 30px;
    margin-top : 20px;
    border-top : 1px solid #ddd;
  }

  .filterTitle {
    color        : #575560;
    font-size    : 14px;
    margin-bottom: 14px;
  }

  .filterList {
    margin    : 0;
    padding   : 0;
    list-style: none;

    li {
      position     : relative;
      margin-bottom: 5px;

      a {
        color          : #333e48;
        text-decoration: none;
        font-weight    : 400;
        font-size      : 16px;
        margin-right   : 5px;
        display        : inline-block;

        &::before {
          content     : "\f0c8";
          font-family : 'font awesome 5 free';
          font-weight : 400;
          margin-right: 5px;
          color       : #dfdfdf;
        }

        &:hover {
          &::before {
            content: "\f14a";
          }
        }
      }

      .count {
        color    : #acacac;
        font-size: 14px;
      }
    }
  }
}

.latestProductWrap {
  margin : 50px 0;
}

.latestProductSingleItem {
  .latestProductImg {
    a {
      img {}
    }
  }

  .productInfo {
    h6 {
      a {
        color      : #333e48;
        font-weight: 400;
        font-size  : 14px;
      }
    }

    .reviewStar {
      margin-bottom: 5px;
    }

    .price {
      .prePrice {
        del {
          color    : #8f8f8f;
          font-size: 12px;
        }
      }

      .NowPrice {
        p {
          font-weight: 600;
        }
      }
    }
  }
}



/*



    position: absolute;
    content: "\f0c8";
    font-family: 'font awesome 5 free';
    font-weight: 400;
    content: "\f14a";
}



*/