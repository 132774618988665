
.header_main_menu {
  background: $brand_color;

  .header_main_navbar {
    padding: 0;

    .navbar-toggler {
      i {

      }
    }
  }
}

.main_menu {
  .navbar-nav {
    padding: 0;

    .nav-item {
      position: relative;

      &:last-child {
        .nav-link {
          border-right: none;
        }
      }

      .nav-link {
        padding: 0;
        font-size: $f-14;
        font-weight: 400;
        font-family: $pop;
        text-transform: capitalize;
        color: $w_color;
        padding: 15px;
        border-right: 1px solid $brand_dark;

        &:after {
          font-family: font-electro;
          border-top: none;
          border-left: none;
          border-right: none;
          width: auto;
          height: auto;
          font-size: .786em;
          line-height: 1;
          content: "\79";
          margin-left: 5px;
          margin-top: 1px;
          opacity: 0;
        }

        &:hover {
          background: $brand_hover;
        }
      }
      &.dropdown {
        .nav-link {
          &:after {
            opacity: 1;
          }
        }
      }

      .dropdown-menu {
        margin: 0;
        display: block;
        border: none;
        background: $brand_dark;
        padding: 0;
        -webkit-box-shadow: 0px 10px 30px 0px rgba(18, 1, 64, 0.08);
        box-shadow: 0px 10px 30px 0px rgba(18, 1, 64, 0.08);
        border-radius: 0;
        -webkit-border-radius: 0;
        position: absolute;
        top: 50px;
        transform: translateY(10px);
        left: 0;
        min-width: max-content;
        min-width: -webkit-max-content;
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        opacity: 0;
        visibility: hidden;

        li {
          display: flex;
          align-items: center;
          font-size: $f-12;
          border-bottom: 1px solid $brand_light;

          &:last-child {
            border-bottom: none;
          }

          .dropdown-menu {
            top: 0px;
            left: 100%;
            transform: translateY(10px);
          }

          &:after {
            font-family: font-electro;
            font-size: .786em;
            line-height: 1;
            content: "\79";
            margin-left: -10px;
            margin-right: 15px;
            transform: rotate(-90deg);
            color: $w_color;
            transition: all .5s;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -ms-transition: all .5s;
            -o-transition: all .5s;
            opacity: 0;
          }

          &.dropdown {
            &:after {
              opacity: 1;
            }
          }

          a {
            display: block;
            font-size: $f-12;
            font-weight: 400;
            font-family: $pop;
            text-transform: capitalize;
            color: $w_color;
            text-decoration: none;
            padding: 10px 25px;
            transition: all .5s;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -ms-transition: all .5s;
            -o-transition: all .5s;

            &:hover {
              padding-left: 35px;
              padding-right: 15px;
            }
          }

        }
      }

      &.dropdown {
        &:hover > .dropdown-menu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }
    }
  }
}