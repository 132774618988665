.header {
  .navbar {
    padding: 20px 0;

    .logo {
      a {
        display: inline-block;

        img {
          max-width: 130px;
          width: 100%;
        }
      }
    }
  }

  .center_search_box {

  }

  .manin_search_box {
    display: flex;
    align-items: center;

    .bar_icon {

    }

    .search_input {
      display: flex;
      align-items: center;
      border: 2px solid $brand_color;
      border-radius: 20px;
      margin-left: 30px;
      position: relative;
      padding-right: 70px;

      .form-control {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        background: transparent;
        width: 420px;
      }

      .catagory_list {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        background: transparent;
        outline: none;
        max-width: 160px;
        width: 100%;
      }

      .search_btn {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        background: $brand_color;
        color: $w_color;
        position: absolute;
        right: -2px;
        top: 0;
        display: flex;
        height: 100%;
        align-items: center;
        width: 55px;
        justify-content: center;
        outline: none;
        border-radius: 0 20px 20px 0;
      }
    }
  }

  .header_right_icon {
    .right_iocn_menu {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-right: 40px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        a {
          text-decoration: none;
          color: $dark_color;
          line-height: 20px;
          font-size: $f-14;
          font-weight: 400;
          display: flex;
          align-items: center;

          .count_no {
            font-size: $f-8;
            font-weight: 400;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $brand_color;
            color: $w_color;
            position: absolute;
            bottom: -5px;
            left: 10px;
          }
          .amount {
            font-weight: 600;
          }
        }

        i {
          margin-right: 10px;
          font-size: 25px;
        }


      }
    }
  }
}