@charset "UTF-8";

/*@font-face {*/
/*    font-family: "font-electro";*/
/*    src:url("../scss/theme/fonts/icons/fonts/font-electro.eot");*/
/*    src:url("../scss/theme/fonts/icons/fonts/font-electro.eot?#iefix") format("embedded-opentype"),*/
/*    url("../scss/theme/fonts/icons/fonts/font-electro.woff") format("woff"),*/
/*    url("../scss/theme/fonts/icons/fonts/font-electro.ttf") format("truetype"),*/
/*    url("../scss/theme/fonts/icons/fonts/font-electro.svg#font-electro") format("svg");*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/

[data-icon]:before {
    font-family: "font-electro" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "font-electro" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mr {
    display: inline-block;
    font: normal normal normal 14px/1 font-electro;
    font-size: inherit;
    text-rendering: auto;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mr-add-to-cart:before {
    content: "\61";
}
.mr-comment:before {
    content: "\63";
}
.mr-compare:before {
    content: "\64";
}
.mr-customers:before {
    content: "\65";
}
.mr-dollar:before {
    content: "\66";
}
.mr-favorites:before {
    content: "\67";
}
.mr-grid-view:before {
    content: "\68";
}
.mr-list-view:before {
    content: "\69";
}
.mr-phone:before {
    content: "\6b";
}
.mr-returning:before {
    content: "\6c";
}
.mr-search:before {
    content: "\6d";
}
.mr-shopping-bag:before {
    content: "\6e";
}
.mr-support:before {
    content: "\6f";
}
.mr-tag:before {
    content: "\70";
}
.mr-thing-arrow-right:before {
    content: "\71";
}
.mr-user:before {
    content: "\72";
}
.mr-arrow-right-categproes:before {
    content: "\62";
}
.mr-blog-likes:before {
    content: "\6a";
}
.mr-category-icon:before {
    content: "\73";
}
.mr-mail:before {
    content: "\74";
}
.mr-map-pointer:before {
    content: "\75";
}
.mr-newsletter:before {
    content: "\76";
}
.mr-close-remove:before {
    content: "\77";
}
.mr-payment:before {
    content: "\78";
}
.mr-arrow-down-search:before {
    content: "\79";
}
.mr-list-view-small:before {
    content: "\7a";
}
.mr-list-view-medium:before {
    content: "\41";
}
.mr-transport:before {
    content: "\42";
}
.mr-cameras:before {
    content: "\e900";
}
.mr-drone:before {
    content: "\e901";
}
.mr-gamepad:before {
    content: "\e902";
}
.mr-headphones:before {
    content: "\e903";
}
.mr-laptop:before {
    content: "\e904";
}
.mr-printer:before {
    content: "\e905";
}
.mr-smartphones:before {
    content: "\e906";
}
.mr-smartwatch:before {
    content: "\e907";
}
.mr-speaker:before {
    content: "\e915";
}
.mr-tvs:before {
    content: "\e916";
}
.mr-menu:before {
    content: "\e908";
}
