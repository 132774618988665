@import "./theme/variable";
@import "./theme/global_style";
@import "./theme/fonts/icons/icons.css";
@import "./theme/header/navbar_top";
@import "./theme/header/navbar";
@import "./theme/header/mainMenu";
@import "./theme/product/product";
@import "./theme/product/productDetails";
@import "./theme/footer/FooterNewsLetter";
@import "./theme/footer/footer_top";


// Only For Icon
@font-face {
  font-family: "font-electro";
  src:url("../scss/theme/fonts/icons/fonts/font-electro.eot");
  src:url("../scss/theme/fonts/icons/fonts/font-electro.eot?#iefix") format("embedded-opentype"),
  url("../scss/theme/fonts/icons/fonts/font-electro.woff") format("woff"),
  url("../scss/theme/fonts/icons/fonts/font-electro.ttf") format("truetype"),
  url("../scss/theme/fonts/icons/fonts/font-electro.svg#font-electro") format("svg");
  font-weight: normal;
  font-style: normal;
}