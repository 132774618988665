.productDetailsWrap {}

.sideBarMenuWrap {}

.sideBarMenuWidget {
    ul {
        padding   : 0;
        margin    : 0;
        list-style: none;

        li {
            border-top: 1px solid #ddd;

            &:first-child {
                border: none;
            }

            a {
                color          : #333e48;
                text-decoration: none;
                font-size      : 14px;

                span {
                    font-size: .857em;
                    color    : #acacac;
                }
            }
        }
    }

    .sideBarMenuContent {
        border       : 2px solid #ececec;
        border-radius: 6px;

        .productCat {
            .sideBarMenu {
                li {
                    .accordion {
                        .btn-link {
                            padding        : 15px 30px;
                            border-bottom  : 1px solid #ececec;
                            display        : block;
                            cursor         : pointer;
                            width          : 100%;
                            text-align     : left;
                            font-size      : 14px;
                            color          : #333e48;
                            text-decoration: none;
                            border-radius  : 0;
                            position       : relative;

                            .icon {
                                position  : absolute;
                                top       : 50%;
                                right     : 20px;
                                transform : translateY(-50%);
                                font-size : 20px;
                                transition: all 0.5s;
                            }
                        }

                        .collapseMenu {
                            ul {
                                padding: 15px;

                                li {
                                    a {
                                        display: block;
                                        padding: 5px 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .categoryListMenu {
                padding: 10px 15px;

                li {
                    border-top: none;

                    a {
                        display      : block;
                        padding      : 15px;
                        border-bottom: 1px solid #ddd;
                    }

                    .categoryList {
                        padding: 15px;

                        li {

                            a {
                                display       : block;
                                padding       : 8px 20px;
                                padding-bottom: .643em;
                                border        : none;

                                &.active {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.productDetailsBody {
    margin-bottom: 30px;

    .productZoom {
        z-index: 1;
    }

    .productDetailsContent {
        .categoryTags {
            margin: 0;

            a {
                font-size  : 14px;
                color      : #878787;
                display    : inline-block;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .productTitle {
            font-size    : 24px;
            line-height  : 28px;
            margin-bottom: 10px;
            color        : #333e48;
        }

        .Availability {
            font-size: 14px;
            color    : #8f8e94;

            span {
                font-weight: 700;
                color      : #5cb85c;
            }
        }

        .wishListLink {
            p {
                a {
                    color       : #949494;
                    font-size   : 14px;
                    margin-right: 15px;

                    i {
                        margin-right: 5px;
                    }
                }
            }
        }

        .short_description {
            margin-bottom: 50px;

            li {
                color    : #949494;
                font-size: 14px;
            }
        }

        .prodductPrice {
            color        : #333e48;
            font-size    : 35px;
            font-weight  : 400;
            margin-bottom: 30px;
        }

        .orderNow {
            display      : flex;
            align-items  : center;
            margin-bottom: 30px;

            .quantityInput {
                margin-right: 20px;

                .quantity {
                    width        : 130px;
                    height       : 44px;
                    border       : 1px solid #ddd;
                    border-radius: 25px;
                    padding      : 10px 20px;
                    box-shadow   : none;
                    outline      : none;
                }
            }

            .callNow {
                .btn {
                    height       : 44px;
                    border-radius: 25px;
                    padding      : 10px 20px;
                    box-shadow   : none;
                    outline      : none;
                    background   : $brand_light;
                    border       : none;

                    &:hover {
                        background: $brand_color;
                    }
                }
            }
        }
    }
}



.reviewStar {
    color        : #878787;
    font-size    : 13px;
    margin-bottom: 20px;

    i {
        margin: 0 1px;

        &.done {
            color: #fed700;
        }
    }

    a {
        color      : #878787;
        display    : inline-block;
        margin-left: 10px;
    }
}

.productDetailsTab {
    margin-bottom: 50px;
    .nav {
        justify-content: center;
        border         : none;

        .nav-item {
            font-size: 18px;
            color    : #333e48;
            border   : none;
            margin   : 0;
            position : relative;

            &::before {
                position  : absolute;
                content   : "";
                width     : 0;
                bottom    : 0;
                height    : 2px;
                background: $brand_color;
                left      : 0;
                right     : 0;
                margin    : 0 auto;
                transition: all 0.5s;
            }

            &.active {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    .tab-content {
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 10px;
        .tab-pane {
            &.active {}
            .QuestionsForm {
                .form-group {
                    .form-control {

                    }
                    textarea.form-control {
                        height: 145px;
                    }
                }
            }
        }
    }
}