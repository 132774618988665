.footer_newsletter {
  background: $brand_color;
  color: $w_color;
  padding: 10px 0;

  .footer-newsletter-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .newsletter-content {
      display: flex;
      align-items: center;

      .newsletter-title {
        margin: 0;
        display: inline-block;
        font-size: $f-20;
        font-weight: 100;
        margin-right: 25px;
        line-height: 48px;
        margin-bottom: 0;
        &:before {
          content: "\76";
          font-family: font-electro;
          margin-right: 15px;
          font-size: 1.619em;
          vertical-align: middle;
        }
      }

      .newsletter-marketing-text {
        margin-left: 25px;
        font-size: $f-15;
        font-weight: 100;
        b {
          font-weight: 500;
        }
      }
    }

    .newsletter-form {
      .input-group {
        .form-control {
          width: 320px;
          padding: 7px 20px;
          border-radius: 20px;
          -webkit-border-radius: 20px;
          box-shadow: none;
          -webkit-box-shadow: none;
          outline: none;
          border: none;

          &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .input-group-btn {
          .btn {
            border-radius: 20px;
            -webkit-border-radius: 20px;
            height: 40px;
            background: $brand_red;
            border: none;
            padding: 5px 25px;
            &:last-child {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            &:hover {
              background: $brand_red_light;
            }
          }
        }
      }
    }
  }
}